var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-events-wrapper"},[_c('h3'),_c('table',{staticClass:"table table-bordered hour-detail"},[_c('thead',[_c('tr',_vm._l((_vm.segments),function(segment,i){return _c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(`${_vm.detailEvents.ind}:${segment.label}`, 'H:m').format('HH:mm'))+" "),_c('br'),_vm._v("-"),_c('br'),_vm._v(" "+_vm._s(_vm.moment(`${_vm.detailEvents.ind}:${segment.label}`, 'H:m').add(5, 'minutes').format('HH:mm')))])}),0)]),_c('tbody',[_c('tr',{staticClass:"tr-detail-hour"},[_vm._l((_vm.segments),function(segment,i){return _c('td')}),_vm._l((_vm.events),function(event,k){return (event.status === 'online')?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: true }),expression:"{ placement: 'top', arrow: true }"}],key:`${k}`,staticClass:"event-point detail-point",class:event.classBind,style:({
                      'left': `calc(${event.detailPercent}% + -0.666px)`,
                      // 'width': `${event.dayWidth}px` }
                      'width': `${ event.endWidth ? event.endWidth : event.dayWidth }px` }),attrs:{"content":event.title}}):_vm._e()}),_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%","left":"0","right":"0","padding":"0"}},_vm._l((_vm.detailEvents.pauses),function(tt){return _c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: true }),expression:"{ placement: 'top', arrow: true }"}],staticClass:"pause-statement",class:[tt.classBind],style:({
                        'left': `${ tt.isStartHere ? ((parseInt(tt.start.format('m'))/60) * 100) +'%' : 'auto'}`,
                        'right': `${ tt.isEndHere ? (100 -((parseInt(tt.end.format('m'))/60) * 100)) + '%' : 'auto' }`,
                        'width': `${ tt.isStartHere && tt.isEndHere ? ((parseInt(tt.end.format('m')) - parseInt(tt.start.format('m'))) /60) * 100 + '%': '100%'}`
                        }),attrs:{"content":tt.title}})}),0)],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }