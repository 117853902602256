<template>
  <section class="card calendar-card" style="overflow: auto">
    <div class="card-header d-flex justify-content-md-between align-items-md-center flex-colum flex-md-row" style="padding-bottom: 0;">
      <h4 class="inside-header">{{ $t("calendar.calendar") }}</h4>
      <date-range-picker ref="picker" class="range-picker" :append-to-body="true" :ranges="calendarTranslate.ranges[$i18n.locale]" v-model="dateRange"
            opens="left"
             :locale-data="calendarTranslate.calendar[$i18n.locale]">
          <template v-slot:input="picker">
            <i class="feather icon-calendar m-r-5"></i>
            {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
          </template>
            <div slot="footer" slot-scope="data" class="calendar-footer slot d-flex justify-content-center justify-content-md-end flex-column flex-md-row">
              <div class="d-flex justify-content-center align-items-center">
                <b>{{ data.rangeText }}</b>
              </div>
              <div class="d-grid p-10 d-lg-flex">
                <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{
                  $t('billing.applyLabel') }}</b-button>
                <button @click="data.clickCancel" class="btn btn-secondary">{{ $t('billing.cancelLabel') }}</button>
              </div>
            </div>
          </date-range-picker>
    </div>
    <div class="card-content" style="min-width: 1272px; max-width: 1272px; ">
      <div>
        <!-- <div class="calendarTabs">
          <div class="tab-title" :class="{ 'selected': isMonth }" @click="selectContent(true)">{{ $t("calendar.month") }}
          </div>
          <div class="tab-title" :class="{ 'selected': !isMonth }" @click="selectContent(false)">{{
            $t("calendar.details") }}</div>
        </div> -->
        <div class="calendarContainer">
          <!-- <div class="monthContainer" style="padding-bottom: 2rem">
            <month-calendar v-on:setdate="setDate" v-on:setload="setLoad" />
          </div> -->
          

          <div class="weekContainer" v-if="!isLoading">
            <!-- <div class="selectContainer">
              <b-button @click="prevWeek" variant="primary" :disabled="isLoading || progress < 100"><i
                  class="feather icon-chevron-left"></i></b-button>
              <div style="color: black; line-height: 40px; margin-left: 1rem; margin-right: 1rem">{{ startWeekFormatted |
                capitalize }} - {{ endWeekFormatted | capitalize }}</div>
              <b-button @click="nextWeek" variant="primary" :disabled="isLoading || progress < 100"><i
                  class="feather icon-chevron-right"></i></b-button>
            </div> -->
            <div class="weekCalendarContainer">
              <div class="timeBlock">
                <div class="table-divider"></div>
                <div class="time-label">{{ $t('monitoring.hours') }}</div>
                <div class="days-label">{{ $t('monitoring.days') }}</div>
                <div class="time" v-for="(hour, index) in hours" :key="index">{{ hour.time }}</div>
              </div>
              <div class="calendar-content">
                <div class="calendar-row" v-for="(day, index) in allWeek" :key="index">
                  <div class="day">
                    <span class="text-capitalize">{{ day.name }}</span>
                    <span>{{ day.date }}</span>
                  </div>

                  <div class="event-point" v-for="(event, k) in onlineStatuses[index]" v-if="event.status === 'online'"
                    :key="`${k}*${index}`" :content="event.title" v-tippy="{ placement: 'top', arrow: true }"
                    :class="event.classBind"
                    :style="{
                              // 'left': `calc((${event.percent}/100)*(100% - (4rem + 7px)) + (4rem + 7px))`,
                              'left': `calc((${event.percent}/100)*(100% - (4rem + 7px)) + (4rem + 7px))`,
                              'width': `${event.width}px`

                    }">
                  </div>
                  <div class="calendar-box" v-for="(box, ind) in hours" :key="ind" @click="detailHourModal({day, index, ind, events: onlineStatuses[index], pauses: intersect(moment(`${day.fullDate} ${box.time}`, 'DD.MM.YYYY H'))})">
                    <div class="pause-statement" :class="[tt.classBind]"
                    :style="{
                      'left': `${ tt.isStartHere ? ((parseInt(tt.start.format('m'))/60) * 100) +'%' : 'auto'}`,
                      'right': `${ tt.isEndHere ? (100 -((parseInt(tt.end.format('m'))/60) * 100)) + '%' : 'auto' }`,
                      'width': `${ tt.isStartHere && tt.isEndHere ? ((parseInt(tt.end.format('m')) - parseInt(tt.start.format('m'))) /60) * 100 + '%': '100%'}`
                      }" 
                    :content="tt.title" v-tippy="{ placement: 'top',followCursor: true, arrow: true}"
                    v-for="tt of intersect(moment(`${day.fullDate} ${box.time}`, 'DD.MM.YYYY HH'))"></div>
                  </div>
                </div>
              </div>
              <b-modal ref="detailEventsModal" 
                :title="detailEvents ? `${capitalize(detailEvents.day.fullName)}, ${detailEvents.day.fullDate}, ${moment(detailEvents.ind, 'H').format('HH:mm')} - ${moment(detailEvents.ind, 'H').add(1, 'hour').format('HH:mm')}`: ''" 
                centered size="xl" hide-footer>
                <detailHour :detail-events="detailEvents" />
              </b-modal>
            </div>
          </div>
          <div class="calendar-loader-container" v-else>
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MonthCalendar from "./MonthCalendar";
import { mapGetters } from "vuex";
import moment from "moment";
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import filters from "../../mixins/filters";
import DateRangePicker from 'vue2-daterange-picker'
import "@/components/billing/pickerstyle.css"
import calendarTranslate from "@/configs/monitoringDateRange";
import detailHour from '@/components/monitoring/detailHour';
import {findWhere, isUndefined} from 'underscore';



export default {
  name: "calendar",
  components: {
    MonthCalendar,
    DateRangePicker,
    detailHour
  },
  mixins: [filters],
  setup() {
    const capitalize = (value) =>
    {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return {
      moment,
      calendarTranslate,
      capitalize
    }
  },
  data() {
    return {
      startWeek: moment().subtract(6, 'days'),
      dateRange: {
        startDate: moment().subtract(6, 'days').toDate(),
        endDate:   moment().toDate(),
      },
      events: {},
      activeTab: 0,
      monthDays: [],
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      },
      settingsMobile: {
        suppressScrollY: true,
        suppressScrollX: false,
        wheelPropagation: false
      },
      bars: true,
      isMobile: false,
      isLoading: false,
      isMonth: true,
      detailEvents: null,
    }
  },
  methods: {
    hourEvents(event) {
      let detailEvents = event
      console.log(event)
      return !isUndefined(detailEvents.events) ? detailEvents.events.filter(item => {
        return moment(item.created_at, 'X').format('H') == detailEvents.ind;
      }).map(event => Object.assign(event, {detailPercent: (parseInt(moment(event.created_at, 'X').format('m'))/60) * 100 })) : []
    },
    selectContent(val) {
      this.isMonth = val
    },
    getOnlineStatuses() {
      if (this.selectedIndexes.length) {
        this.isLoading = true;
        this.$store.dispatch('monitoring/getOnlineStatuses', {
          filters: {
            target_id: this.selectedIndexes,
            status: ['online'],
            source: this.selectedSources === 'event_monitors' ? undefined : this.selectedSources,
            created_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            created_to: moment(this.dateRange.endDate).endOf('day').format('X')
          },
          order_by: 'created_at',
          order_type: 'asc',
          limit: 200,
          offset: 0
        }).finally(() => {
          this.isLoading = false;
        })
      } else {
        this.$store.dispatch('monitoring/clearOnlineStatuses')
      }
    },
    getTasks() {
      if(this.selectedIndexes.length) {
        this.$store.dispatch('monitoring/getTasks', {
          filters: {
            target_id: this.selectedIndexes,
          }
        })
      }
    },
    prevWeek() {
      this.startWeek = this.startWeek.clone().subtract(1, 'week');
      this.getOnlineStatuses();
    },
    nextWeek() {
      this.startWeek = this.startWeek.clone().add(1, 'week');
      this.getOnlineStatuses();
    },
    getDaysInMonth(month, year) {
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        let momentDay = moment(new Date(date));
        let dayIndex = parseInt(momentDay.format('E'))
        if (days.length === 0) {
          for (let i = 1; i < dayIndex; i++) {
            days.push({});
          }
        }
        days.push({
          date: momentDay.format('DD'),
          day: dayIndex
        });
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    checkMobile() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    },
    setDate(event) {
      let momentWeek = moment(event).startOf('isoWeek');
      this.isMonth = false;
      if (event && !this.startWeek.isSame(moment(event).startOf('isoWeek'))) {
        this.startWeek = momentWeek;
        this.getOnlineStatuses()
      }
    },
    setLoad(load) {
      this.isLoading = load;
    },
    detailHourModal(info) {
      this.detailEvents = info
      this.$refs.detailEventsModal.show();
    },
    onlineSegmentsByHour(){},



    intersect(timeMoment) {
      const start1 = timeMoment;
      const end1 = timeMoment.clone().add(1, 'hour')
      const intersectedIds = {};
      if(this.pauses) {
        for(const targetId of Object.keys(this.pauses)) {
          const target = findWhere(this.targets, {id: parseInt(targetId)})
          let selectedTargetIndex = this.selectedIndexes.findIndex(item => item === target.target_id )
          let pauseClass = 'color-'+selectedTargetIndex
            for(const segment of this.pauses[targetId]) {
              const wrappedSegment = this.wrapPauseMoment(segment)
              const intersectionStart = moment.max(start1, wrappedSegment.start);
              const intersectionEnd = moment.min(end1, wrappedSegment.end);
              if (intersectionStart.isSameOrBefore(intersectionEnd, 'hour')) {
                intersectedIds[targetId] = {
                  isStartHere: wrappedSegment.start.isBetween(start1, end1),
                  isEndHere: wrappedSegment.end.isBetween(start1, end1),
                  start: wrappedSegment.start,
                  end: wrappedSegment.end,
                  start1,
                  end1,
                  title: `${target.alias} ${this.$t('monitoring.pause')} <br/> ${wrappedSegment.start.format('DD.MM.YYYY, HH:mm')} - ${wrappedSegment.end.format('DD.MM.YYYY, HH:mm')}`,
                  classBind: pauseClass
                };
              }
            }
        }
      }
      return intersectedIds;
    },
    wrapPauseMoment(pause) {
      return {
        start: moment(pause.start, 'X'),
        end: moment(pause.end, 'X')
      }
    }
    
  },
  mounted() {
    this.checkMobile();
    window.onresize = () => {
      this.checkMobile();
    };
    this.getOnlineStatuses();
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
    },
    allWeek() {
      let days = [];
      moment.locale(this.locale);
      const diffDays = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days')
      for (let day = 0; day <= diffDays; day++) {
        const dayMoment = moment(this.dateRange.startDate).add(day, 'days');
        days.push({name: dayMoment.format('dd'), fullName: dayMoment.format('dddd'), date: dayMoment.format('DD.MM'), fullDate: dayMoment.format('DD.MM.YYYY')})
        
      }
      return days;
    },
    onlineStatuses() {
      return this.$store.state.monitoring.groupedEvents;
    },
    ...mapGetters('monitoring', ['groupedStatuses', 'colors', 'progress', 'selectedSources', 'pauses', 'targets']),
    startWeekInt() {
      return this.startWeek.format('X');
    },
    endWeek() {
      return this.startWeek.clone().endOf('isoWeek')
    },
    endWeekInt() {
      return this.startWeek.clone().endOf('isoWeek').format('X')
    },
    // startWeekFormatted() {
    //   return this.startWeek.format("MMMM DD YYYY, h:mm:ss a")
    // },
    startWeekFormatted() {
      return this.startWeek.locale(this.locale).format("DD MMMM YYYY")
    },
    // endWeekFormatted() {
    //   return this.endWeek.format("MMMM DD YYYY, h:mm:ss a")
    // },
    endWeekFormatted() {
      return this.endWeek.locale(this.locale).format("DD MMMM YYYY")
    },
    hours() {
      const hours = [];
      for (let i = 0; i <= 23; i++) {
        let hour = i < 10 ? '0' + i : i;
        hours.push({ time: hour });
      }
      return hours;
    },
    selectedIndexes() {
      return this.$store.state.monitoring.selectedIndexes
    },
  },
  watch: {
    selectedIndexes(val) {
      this.getOnlineStatuses();
      if(!val.length) {
        this.$store.dispatch('monitoring/clearPauses')
      }
    },
    selectedSources() {
      this.getOnlineStatuses();
    },
    dateRange: {
      deep: true,
      handler() {
        this.getOnlineStatuses();
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('monitoring/clearPauses')
  }
}
</script>

<style lang="scss">
.calendar-row {
  position: relative;
  &:first-child {
    border-top: 1px solid #fff;
  }
}

.calendarTabs {
  color: black;
  display: flex;
  justify-content: space-between;
  text-align: center;

  .tab-title {
    padding: 1rem;
    width: calc(50% - 1px);
    height: 100%;
    border-bottom: 1px solid #4099ff;
    cursor: pointer;

    &:hover {
      background-color: #d9ebff;
    }
  }

  .selected {
    background-color: #d9ebff;
  }

}

.card-content {
  padding: 0.75rem;
}


.weekCalendarContainer {
  min-width: 1225px;
  max-width: 1225px;
  color: white;
}

.timeBlock {
  display: flex;
  height: calc(4rem + 7px);
  width: 100%;
  background-color: #4099ff;
  padding-left: calc(4rem + 7px);
  min-width: 1000px;
  position: relative;
}

.table-divider {
  transform: rotate(45deg);
  top: 37px;
  width: 105px;
  position: absolute;
  left: -15px;
  border-top: 1px solid #fff;
}

.time-label {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 12px;
  // width: calc(4rem + 7px);
  text-align: right;
  padding-top: 5px;
  padding-right: 5px;
  width: 94px;
  transform: rotate(45deg);
  text-align: center;
}

.days-label {
  position: absolute;
  left: -16px;
  top: 35px;
  font-size: 12px;
  width: 94px;
  transform: rotate(45deg);
  text-align: center;
}

.time {
  width: calc((100%) / 24);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid white;

  
    border-left: 1px solid #fff;
  &+.time {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}

.calendar-row {
  display: flex;
  min-width: 1000px;
}

.day {
  color: #000;
  width: calc(4rem + 7px);
  height: 4rem;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  background-color: #d9ebff;
  border-bottom: 1px solid white;
}

.calendar-row:last-child .day {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.calendar-box {
  height: 4rem;
  width: calc((100% - (4rem + 7px)) / 24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  overflow-x: hidden;
  position: relative;
  &:hover {
    background: #efefef;
  }
}

.pause-statement {
  position: absolute;
  top: 0;
  height: 20%;
  width: 100%;
  //display: inline-block;
  background: #c8c8c8;
  border-bottom: 1px solid #bebebe;
  &.color-0 {
    top: 0;
  }

  &.color-1 {
    top: 20%;
  }

  &.color-2 {
    top: 40%;
  }

  &.color-3 {
    top: 60%;
  }

  &.color-4 {
    top: 80%;
  }
}

.weekCalendarContainer {
  padding-bottom: 20px;
}

@media (max-width: 1410px) {
  .weekCalendarContainer {
    //overflow-x: scroll;
  }
}

.calendar-loader-container {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}




.bg-black {
  background: #000000;
}

.event-point {
  //width: 30%;
  top: -1px;
  width: 4px;
  height: 20%;
  position: absolute;
  cursor: pointer;
  z-index: 2;

  &.color-0 {
    top: 0;
    background-color: #007bff;
  }

  &.color-1 {
    top: 20%;
    background-color: #28a745;
  }

  &.color-2 {
    top: 40%;
    background-color: #17a2b8;
  }

  &.color-3 {
    top: 60%;
    background-color: #dc3545;
  }

  &.color-4 {
    top: 80%;
    background-color: #37474f;
  }
}

.calendar-card {
  min-height: 500px;
}
@media (min-width: 768px) {
  .calendar-card {
    min-height: 600px;
  }
}

@media (min-width: 768px) {  
  .range-picker {
    margin-top: -8px;
  }
}
</style>


<style>


.holder-progress {
  height: 12px;
  margin-bottom: 1.5rem;
}

.selectContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 992px) {
  .calendar-footer .acceptButton{
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 991px) {
    .calendar-footer .acceptButton{
      margin-bottom: 8px;
    }

    .calendar-footer {
      padding-bottom: 30px;
    }
    body .daterangepicker {
      max-width: 285px;
    }
  }
</style>